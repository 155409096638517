
import type { BillingScopeGlobalSettings, BillingScopeResponse} from '@azure/services/billingScope';
import type { CreateServicePrincipalResponse } from '@azure/services/servicePrincipal';

import { AssertionError } from 'assert';
import { defineStore } from 'pinia';
import { computed } from 'vue';

import { AgreementType } from '@azure/services/billingScope';
import * as billingScope from '@azure/services/billingScope';
import * as servicePrincipal from '@azure/services/servicePrincipal';
import * as mask from '@console/lib/mask';
import { getVuexStore } from '@console/state/vuex/store';
import { useFeatureStore } from '@shared/state/feature.store';

export type OnboardingForm = {
  agreementType: AgreementType;
  billingAccountId?: string;
  billingProfileId?: string; // MCA only, not Enterprise Agreement
  applicationId?: string;
  tenantId?: string;
  storageAccountSubscriptionId: string;
};

export type AzureState = {
  billingScopes: { [id: string]: BillingScopeResponse } | undefined;
  billingScopeGlobalSettings: { [id: string]: BillingScopeGlobalSettings };
  onboarding: {
    form: OnboardingForm;
    servicePrincipal?: CreateServicePrincipalResponse;
  };
};

/** Mask azure billing scope for demo */
const maskBillingScope = (billingScope: BillingScopeResponse) => {
  return Object.assign({}, billingScope, {
    display_name: mask.generatAzureBillingAccountDisplayName(billingScope.billing_account_id),
    billing_account_id: mask.generateAzureBillingAccountName(),
    billing_profile_id: mask.generateAzureBillingAccountName(),
    storage_account_subscription_id: mask.generateAzureSubscription(),
    storage_account_resource_group_name: mask.generateAzureResourceGroup(),
    storage_account_name: mask.generateAzureResourceGroup(),
  });
};


function defaultOnboardingState() {
  return {
    form: <OnboardingForm>{
      agreementType: AgreementType.EnterpriseAgreement
    },
  };
}

function assertServicePrincipal(
  value: CreateServicePrincipalResponse | undefined
): asserts value is CreateServicePrincipalResponse {
  if (!value) {
    throw new AssertionError({ message: 'value is not defined' });
  }
}

export const useAzureStore = defineStore('azure', {
  state: (): AzureState => ({
    onboarding: defaultOnboardingState(),
    billingScopes: undefined,
    billingScopeGlobalSettings: {}
  }),
  getters: {
    isBillingScopeActive: (state: AzureState) => (id: string) => {
      const billingScopes = state.billingScopes || {};
      const billingScope = billingScopes[id];

      return billingScope?.status === 'Active';
    },
    getBillingScopeById: (state: AzureState) => (id: string) => {
      if (!state.billingScopes) {
        return undefined;
      }

      return state.billingScopes[id];
    },
    getGlobalSettingsById: (state: AzureState) => (id: string) => {
      return state.billingScopeGlobalSettings[id];
    },
  },
  actions: {
    async reload(): Promise<void> {
      this.billingScopes = undefined;

      await this.loadBillingScopes();
    },
    async createServicePrincipal(): Promise<void> {
      const form = this.onboarding.form;
      const spn = await servicePrincipal.create({
        application_id: form.applicationId,
        tenant_id: form.tenantId,
      });
      this.onboarding.servicePrincipal = spn;
    },
    async createBillingScope(): Promise<void> {
      assertServicePrincipal(this.onboarding.servicePrincipal);

      let scope = await billingScope.create({
        agreement_type: this.onboarding.form.agreementType,
        billing_account_id: this.onboarding.form.billingAccountId,
        billing_profile_id: this.onboarding.form.billingProfileId,
        storage_account_subscription_id: this.onboarding.form.storageAccountSubscriptionId,
        storage_account_resource_group_name: billingScope.generateExportResourceGroupName(),
        storage_account_name: billingScope.generateExportStorageAccountName(this.onboarding.form.storageAccountSubscriptionId),
        service_principal_id: this.onboarding.servicePrincipal.id,
      });
      /** Mask if demo */
      /** check if demo */
      const store = getVuexStore();
      const isDemo = computed(() => store.getters['customer/isDemo']);

      if (isDemo.value === true) {
        scope = maskBillingScope(scope);
      }

      this.billingScopes = { ...this.billingScopes, [scope.id]: scope };
      this.onboarding = defaultOnboardingState();
      store.dispatch('nav/switch', { cloud: 'azure', id: scope.id });
    },
    async activateBillingScope(billingAccountId: string): Promise<void> {
      let scope = await billingScope.activate(billingAccountId);

      const store = getVuexStore();
      const isDemo = computed(() => store.getters['customer/isDemo']);

      if (isDemo.value === true) {
        scope = maskBillingScope(scope);
      }

      this.billingScopes = { ...this.billingScopes, [scope.id]: scope };
    },
    async loadBillingScopes() {
      if (this.billingScopes) {
        return;
      }

      let scopes = await billingScope.getAll();
      /** Mask all billing scopes if demo */
      /** check if demo */
      const vuexStore = getVuexStore();
      const isDemo = computed(() => vuexStore.getters['customer/isDemo']);

      if (isDemo.value === true) {
       scopes = scopes.map((scope) => maskBillingScope(scope));
      }
      const scopesById = scopes.reduce((agg, val) => {
        return { [val.id]: val, ...agg };
      }, {});

      this.billingScopes = scopesById;
    },
    async loadSelectedBillingScope() {
      const vuexStore = getVuexStore();
      const context = computed(() => vuexStore.getters['nav/context']);
      const isAzureContext = computed(() => vuexStore.getters['nav/isAzureContext']);

      if (!isAzureContext.value || context.value.id === 'NEW_BILLING_SCOPE_PLACEHOLDER') {
        return;
      }

      const settings = await billingScope.getGlobalSettings(context.value.id);
      this.billingScopeGlobalSettings = { ...this.billingScopeGlobalSettings, [context.value.id]: settings };
    }
  },
});
